import * as React from "react"
import { graphql, PageProps } from "gatsby"
import "twin.macro"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BlogCard from "../components/ui/BlogCard"
import Title from "../components/ui/Title"

type DataProps = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  allMarkdownRemark: {
    nodes: {
      excerpt: string
      fields: {
        slug: string
      }
      frontmatter: {
        date: string
        title: string
        description: string
        image: any
      }
    }[]
  }
}

const BlogIndex: React.FC<PageProps<DataProps>> = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout>
      <Seo title="All Blogs" />
      <div tw="px-5 mx-auto max-w-7xl space-y-10 min-h-screen md:min-height[85vh] grid place-content-center">
        <div tw="mx-auto text-center">
          <Title>All Blogs</Title>
        </div>

        <div tw="grid md:grid-cols-3  justify-items-center gap-5">
          {posts.map((card, index) => (
            <BlogCard
              name={card.frontmatter.title}
              content={card.excerpt}
              image={card.frontmatter.image}
              key={index}
              slug={card.fields.slug}
            />
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`
